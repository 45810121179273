import _ from 'lodash';
import { AxiosResponse } from 'axios';

import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { authFrontStore, useGridFilter } from '@stores';
import {
  AuctionType,
  AuctionFilters,
  AuctionStatusResponse,
  AuctionTypeResponse,
  AuctionItemTypeResponse,
  AuctionRequest,
  AuctionResponse,
  UserAuctionResponse,
  AuctionDocumentResponse,
  AuctionDocumentTypeResponse,
  AuctionDocumentCreate,
  AuctionImageResponse,
  AuctionImageCreate,
  WebAuctionsFilter,
  AuctionExportsResponse,
  AuctionExportsHistoryResponse,
  AuctionCountResponse,
  HistoryType,
  AuctionConceptType,
  PaginationParams,
  AuctionAuthorizationType,
  AuctionCautionDepositType,
  WebAuctionBid,
  WebAuctionBidRequest,
  AuctionAdminBid,
  BaseObjectType,
  AuctionWinnerResponse,
  AuctionStatusGroupResponse,
  AuctionVideoCreate,
  AuctionParameterResponse,
  AuctionSendMessageValues,
  AuctionStatusGroup,
  AuctionAuctionTypeResponse,
  DocumentCreate,
  DocumentMedia,
  BaseResponse,
  AuctionFieldsEnabled,
  AuctionSecurityCounterInfo,
  AdminAuctionFilters,
  AuctionUsersFilters,
  AuctionMapResponse,
  EnumType,
  AuctionBackSecurityData,
  AuctionUpdateOutageData,
  AuctionEmailType,
  AuctionEmailsFilter,
  OngoingAuctionUpdateOutageData,
} from '@types';

interface AdminAuctionBaseResponse<Data extends any = undefined> extends BaseResponse<Data> {
  fieldsEnabled: AuctionFieldsEnabled;
  requiredParametersFields?: string[];
}

export type AdminAuctionAPIResponse = BaseAPIResponse<AdminAuctionBaseResponse<AuctionResponse>>;

export const auctionItemTypePath = () => `/enum/auction/item-type`;
export const documentTypesPath = (auctionType: string) => `/enum/auction/document-type/${auctionType}`;
export const auctionTypePath = (auctionTypeGroup: string) => `/enum/auction/auction-type/${auctionTypeGroup}`;
export const auctionStatusPath = () => `/enum/auction/status/auction`;
export const allStatusesPath = () => `/enum/auction/all-statuses`;
export const auctionStatusGroupPath = (statusGroup: string) => `/enum/auction/status/group/${statusGroup}`;
export const auctionStatusGroupsPath = (statusGroup: string) => `/enum/auction/status-groups/${statusGroup}`;
export const webAuctionsPath = () => `/web/auction/`;
export const webMapAuctionsPath = () => `/web/auction-map`;
export const webAuctionsCountPath = () => `/web/auction/auctions/counts`;
export const webAuctionsTypesPath = () => `/enum/auction/auction-type`;
export const webAuctionInformationsPath = (auctionId: string | number) => `/web/auction/${auctionId}`;
export const userAuctionsPath = () => `/user/auction`;
export const userAuctionsCountPath = () => `/user/auction/counts`;
export const userJoinAuctionPath = (hash: string) => `/user/auction/${hash}`;
export const userAuctionPath = (id: number | string) => `/user/auction/${id}`;
export const userFavouriteAuctionsPath = () => `/user/favorite-auction`;
export const userFavouriteAuctionPath = (id: number | string) => `/user/favorite-auction/${id}`;
export const auctionsPath = () => `/admin/auction`;
export const auctionTypesPath = () => `/enum/auction/auction-type`;
export const auctionPath = (id: string | number) => `/admin/auction/${id}`;
export const auctionBuyersPath = (auctionId: string | number) => `/admin/auction/${auctionId}/buyer`;
export const auctionSecurityBuyersPath = (auctionId: string | number) => `/admin/security/auction/${auctionId}`;
export const getAuctionRequiredFieldsPath = (auctionId: string | number, categoryId: string | number) =>
  `/admin/auction/${auctionId}/category/${categoryId}`;

export const auctionSecurityExcelPath = (auctionId: string | number) =>
  `/admin/security/auction/${auctionId}/export/xlsx`;
export const auctionSecurityGpcPath = (auctionId: string | number) => `/admin/security/auction/${auctionId}/export/gpc`;
export const auctionSecurityExcelAllPath = () => `/admin/security/auction/export/xlsx`;
export const auctionSecurityGpcAllPath = () => `/admin/security/auction/export/gpc`;

export const auctioneerAuctionTypePath = (auctioneerId: string | number) =>
  `/admin/auctioneer/${auctioneerId}/auction-type`;
export const auctioneerAuctionTypeByTypePath = (
  auctioneerId: string | number,
  auctionType: 'auction' | 'auction_public'
) => `/admin/auctioneer/${auctioneerId}/auction-type/${auctionType}`;
export const getDocumentsPath = (id: string | number) => `/admin/auction/${id}/document`;
export const documentPath = (auctionId: string | number, documentId: string | number) =>
  `/admin/auction/${auctionId}/document/${documentId}`;
export const createDocumentPath = (auctionId: string | number, documentType: string) =>
  `/admin/auction/${auctionId}/document/${documentType}`;
export const renameDocumentPath = (auctionId: string, documentId: string) =>
  `/admin/auction/${auctionId}/document/${documentId}/rename`;
export const auctionExportsHistoryPath = (auctionId: string | number) => `/admin/auction/${auctionId}/export/history`;
export const auctionExportsPath = (auctionId: string | number) => `/admin/auction/${auctionId}/export`;
export const auctionExportPreviewPath = (auctionId: string | number, imagePreview: string) =>
  `/admin/auction/${auctionId}/preview/${imagePreview}`;
export const auctionExportsCancelPath = (auctionId: string | number) => `/admin/auction/${auctionId}/export-cancell`;
export const createDocumentPrivatePath = (auctionId: string | number) => `/admin/auction/${auctionId}/document`;
export const imagesPath = (auctionId: string | number) => `/admin/auction/${auctionId}/media`;
export const mediaPath = (auctionId: string | number, imageId: string | number) =>
  `/admin/auction/${auctionId}/media/${imageId}`;
export const auctionDeletePath = (auctionId: string | number) => `/admin/auction/${auctionId}`;
export const auctionActivatePath = (auctionId: string | number) => `/admin/auction/${auctionId}/activate`;
export const auctionDeactivePath = (auctionId: string | number) => `/admin/auction/${auctionId}/deactive`;
export const reorderAuctionImagesPath = (auctionId: string | number) => `/admin/auction/${auctionId}/media/position`;
export const auctionHistoryPath = (auctionId: string | number) => `/admin/auction/${auctionId}/history`;
export const createConceptPath = () => `/admin/auction-concept`;
export const copyConceptPath = (id: string | number) => `/admin/auction-concept/${id}/copy`;
export const updateConceptPath = (id: string | number) => `/admin/auction-concept/${id}`;
export const deleteConceptPath = (id: string | number) => `/admin/auction-concept/${id}`;
export const getConceptPath = (conceptId: string | number) => `/admin/auction-concept/${conceptId}`;
export const getConceptListPath = () => `/admin/auction-concept`;
export const getAuthorizationTypePath = (auctionId: string | number) =>
  `/admin/auction/authorization-type/${auctionId}`;
export const getCautionDepositPath = (auctionId: string | number) => `/admin/auction/auction-security/${auctionId}`;
export const getCautionDepositCounterInfo = (auctionId: string | number, userId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/auction-security-counter`;
export const getBidsAdmin = (auctionId: string | number) => `/admin/auction/${auctionId}/bids`;
export const getWebBidsPath = (auctionId: string | number) => `/auction/${auctionId}/bids`;
export const createWebBidPath = (auctionId: string | number) => `/auction/${auctionId}/bid`;
export const acceptWebMinBidPath = (auctionId: string | number) => `/auction/${auctionId}/accept-minimal-bid`;
export const winnerPath = (auctionId: string | number) => `/web/auction/${auctionId}/winner`;
export const auctionParameterShortPath = (auctionId: string | number) => `/web/auction/${auctionId}/parameter-short`;
export const auctionParameterCompletePath = (auctionId: string | number) =>
  `/web/auction/${auctionId}/parameter-complete`;
export const auctionMessagesTypePath = () => '/enum/auction-message/type';
export const auctionSendMessagesPath = (auctionId: string | number) => `/admin/auction/${auctionId}/message`;
export const preEmptionBidPath = (auctionId: string | number) => `/auction/${auctionId}/bid-call`;
export const auctionMessageAttachment = () => `/admin/auction/message/attachment`;
export const auctionDrawPath = (auctionId: string | number) => `auction/${auctionId}/draw`;
export const auctionExtendPath = (auctionId: string | number) => `/admin/auction/${auctionId}/extend`;
export const getAuctionPrintPath = (auctionId: string | number) => `/admin/auction/${auctionId}/print`;
export const getAuctionsSecurityPath = () => `/admin/security/auction`;
export const auctionUsersExcelPath = (auctionId: string | number) => `/admin/auction/${auctionId}/buyer/export`;
export const getAuctionBackSecurityPath = (userId: string | number, auctionId: string | number) =>
  `/admin/user/${userId}/auction/${auctionId}/auction-back-security`;
export const updateAuctionOutagePath = (auctionId: string | number) => `/admin/auction/${auctionId}/extend-outage`;
export const updateAuctionOngoingPath = (auctionId: string | number) => `/admin/auction/${auctionId}/extend-ongoing`;
export const auctionEmailsPath = (auctionId: string | number) => `/admin/auction/${auctionId}/email`;

export interface AuctionsAPI extends API {
  getAuctionRequiredFields(auctionId: string | number, categoryId: string | number): APIResponse<string[]>;

  getAuctionStatusGroups(auctionType?: string, currentStatus?: string): APIResponse<AuctionStatusGroupResponse[]>;

  getAuctionStatusGroup(statusGroup: string): APIResponse<Array<AuctionStatusGroup>>;

  getAllStatuses(): APIResponse<EnumType[]>;

  getAuctionAuctionType(auctionTypeGroup: string): APIResponse<Array<AuctionAuctionTypeResponse>>;

  listTypes(): APIResponse<Array<AuctionType>>;

  addAuctionToFavourites(auctionId: number | string): APIResponse<any>;

  removeAuctionFromFavourites(auctionId: number | string): APIResponse<any>;

  joinAuction(auctionId: number | string): APIResponse<any>;

  leaveAuction(auctionId: number | string): APIResponse<any>;

  getFavouriteAuctions(params: AuctionFilters): APIResponse<Array<UserAuctionResponse>>;

  getUserAuctions(params: AuctionFilters): APIResponse<Array<UserAuctionResponse>>;

  getUserAuctionsCount(): APIResponse<AuctionCountResponse[]>;

  getUserAuction(auctionId: string | number): APIResponse<UserAuctionResponse>;

  getWebAuctions(
    params: WebAuctionsFilter,
    sortProp: string,
    sortDirection: string,
    cancelName?: string
  ): APIResponse<Array<AuctionResponse>>;

  getWebMapAuctions(params: WebAuctionsFilter): APIResponse<AuctionMapResponse[]>;

  getWebAuctionsCount(): APIResponse<AuctionCountResponse[]>;

  getWebAuctionTypeList(): APIResponse<Array<AuctionType>>;

  getWebAuctionInformations(auctionId: string | number, type: string): APIResponse<AuctionResponse>;

  getAuction(id: string | number): AdminAuctionAPIResponse;

  getAuctions(params: AdminAuctionFilters): APIResponse<Array<AuctionResponse>>;

  auctionCreate(data: AuctionRequest): APIResponse<AuctionResponse>;

  auctionUpdate(id: string | number, data: AuctionRequest): APIResponse;

  deleteAuction(id: string | number): APIResponse;

  getAuctionStatus(auctionType?: string): APIResponse<Array<AuctionStatusResponse>>;

  getAuctionBuyers(auctionId: string | number, params?: AuctionUsersFilters): APIResponse<Array<UserAuctionResponse>>;

  getAuctionSecurityBuyers(
    auctionId: string | number,
    params?: AuctionUsersFilters
  ): APIResponse<Array<UserAuctionResponse>>;

  getAuctionSecurityExcel(auctionId?: string | number, params?: AuctionUsersFilters): BaseAPIResponse<Blob>;

  getAuctionSecurityGpc(auctionId?: string | number, params?: AuctionUsersFilters): BaseAPIResponse<Blob>;

  getAuctionUsersExcel(auctionId: string | number, params?: AuctionUsersFilters): BaseAPIResponse<Blob>;

  getAuctionType(auctioneerId: string | number): APIResponse<Array<AuctionTypeResponse>>;

  getAuctionTypeByType(
    auctioneerId: string | number,
    auctionType: 'auction' | 'auction_public',
    auctionId?: string | number
  ): APIResponse<Array<AuctionTypeResponse>>;

  getAuctionExports(auctionId: string | number): APIResponse<Array<AuctionExportsResponse>>;

  getAuctionExportsHistory(auctionId: string | number): APIResponse<Array<AuctionExportsHistoryResponse>>;

  exportAuction(
    auctionId: string | number,
    server: string,
    dateFrom?: Date,
    dateTo?: Date,
    weeks?: string,
    previewImage?: string
  ): APIResponse;

  auctionExportPreview(auctionId: string | number, imagePreview: string): BaseAPIResponse<string>;

  cancelExportAuction(auctionId: string | number, server: string): APIResponse;

  getItemType(): APIResponse<AuctionItemTypeResponse>;

  getDocumentTypes(auctionType: string): APIResponse<AuctionDocumentTypeResponse>;

  getDocument(auctionId: string | number, documentId: string | number): Promise<AxiosResponse<any>>;

  getDocuments(id: string | number): BaseAPIResponse<AuctionDocumentResponse[]>;

  deleteDocument(auctionId: string | number, documentId: string | number): APIResponse;

  createDocument(auctionId: string | number, documentType: string, data: AuctionDocumentCreate): APIResponse;

  renameDocument(auctionId: string, documentId: string, title: string): APIResponse;

  createDocumentPrivate(auctionId: string | number, data: AuctionDocumentCreate): APIResponse;

  getImages(auctionId: string | number): BaseAPIResponse<AuctionImageResponse[]>;

  createImage(auctionId: string | number, data: AuctionImageCreate): APIResponse;

  createVideo(auctionId: string | number, data: AuctionVideoCreate): APIResponse;

  deleteImage(auctionId: string | number, imageId: string | number): APIResponse;

  getMedia(auctionId: string | number, mediaId: string | number, size?: string): Promise<AxiosResponse<any>>;

  auctionDelete(auctionId: string | number): APIResponse;

  auctionActivate(auctionId: string | number): APIResponse;

  auctionDeactive(auctionId: string | number): APIResponse;

  reorderAuctionImages(
    auctionId: string | number,
    imagesOrder: Array<{
      auctionMediaId: string | number;
      position: number;
    }>
  ): APIResponse;

  getHistory(auctionId: string | number, perPage?: number, page?: number): APIResponse<HistoryType[]>;

  copyConcept(conceptId: string | number): APIResponse<AuctionConceptType>;

  saveConcept(values: any, auctionType: string, conceptId?: string | number): APIResponse<AuctionConceptType>;

  getContept(conceptId: string | number): APIResponse<AuctionConceptType>;

  getConteptList(params: PaginationParams): APIResponse<AuctionConceptType[]>;

  deleteConcept(id: string | number): APIResponse;

  getAuthorizationType(auctionId: string | number): APIResponse<AuctionAuthorizationType[]>;

  getCautionDepositCounterInfo(
    auctionId: string | number,
    userId: string | number
  ): APIResponse<AuctionSecurityCounterInfo>;

  getCautionDeposit(auctionId: string | number): APIResponse<AuctionCautionDepositType[]>;

  getBidsAdmin(auctionId: string | number): APIResponse<AuctionAdminBid[]>;

  getWebBids(auctionId: string | number): APIResponse<WebAuctionBid[]>;

  acceptWebMinBid(auctionId: string | number): APIResponse;

  createWebBid(auctionId: string | number, values: WebAuctionBidRequest): APIResponse;

  getWinner(auctionId: string | number): BaseAPIResponse<AuctionWinnerResponse>;

  auctionParameterShort(auctionId: string | number): APIResponse<AuctionParameterResponse[]>;

  auctionParameterComplete(auctionId: string | number): APIResponse<AuctionParameterResponse[]>;

  getAuctionMessagesType(): APIResponse<BaseObjectType<string>>;

  sendAuctionMessage(auctionId: number | string, values: AuctionSendMessageValues): APIResponse<any>;

  preEmptionBid(auctionId: string | number, preEmptionBid: string | number): APIResponse;

  uploadMessageAttachment(data: Partial<DocumentCreate>): APIResponse<DocumentMedia>;

  auctionDraw(auctionId: string | number): APIResponse<any>;

  auctionExtend(auctionId: string | number, endDt: string): APIResponse;

  printAuction(auctionId: string | number): BaseAPIResponse<Blob>;

  getAuctionsSecurity(params?: AuctionUsersFilters): APIResponse<UserAuctionResponse[]>;

  auctionBackSecurity(userId: string | number, auctionId: string | number, data: AuctionBackSecurityData): APIResponse;

  updateAuctionOutage(auctionId: string | number, data: AuctionUpdateOutageData): APIResponse;

  updateAuctionOngoing(auctionId: string | number, data: OngoingAuctionUpdateOutageData): APIResponse;

  getAuctionEmails(auctionId: string | number, params?: AuctionEmailsFilter): APIResponse<AuctionEmailType[]>;
}

export const useAuctionsApi = (errorToastDisable?: boolean, successToastDisabled?: boolean): AuctionsAPI => {
  const api = useApi(errorToastDisable, successToastDisabled);

  const webAuctionsFilter = useGridFilter<WebAuctionsFilter>([
    {
      key: 'title',
      group: 'search',
      operator: 'like',
      property: 'auction.title',
    },
    {
      key: 'title',
      group: 'search',
      operator: 'like',
      property: 'auction.number',
      value: (val) => (!!val ? `%${val.replaceAll(' ', '')}%` : undefined),
    },
    {
      type: 'int',
      key: 'priceFrom',
      property: 'auction.minimalBid',
      operator: 'gte',
    },
    {
      type: 'int',
      key: 'priceTo',
      property: 'auction.minimalBid',
      operator: 'lte',
    },
    {
      type: 'string',
      key: 'status',
      property: 'auction.status',
    },
    {
      type: 'int',
      key: 'mainCategory',
      property: 'auctionCategory.parent',
      operator: 'eq',
    },
    {
      group: 'regions',
      type: 'int',
      key: 'regions',
      property: 'auctionAddress.region',
      operator: 'eq',
    },
    {
      group: 'district',
      type: 'int',
      key: 'districts',
      property: 'auctionAddress.district',
      operator: 'eq',
    },
    {
      group: 'districtPart',
      type: 'int',
      key: 'districtsParts',
      property: 'auctionAddress.districtPart',
      operator: 'eq',
    },
    {
      type: 'int',
      operator: 'eq',
      key: 'subCategories',
      group: 'subCategories',
      property: 'auctionCategory.id',
    },
    {
      operator: 'eq',
      type: 'string',
      key: 'auctionType',
      group: 'auctionType',
      property: 'auction.auctionType',
    },
    {
      operator: 'eq',
      type: 'string',
      key: 'auctioneer',
      property: 'auctioneer.id',
    },
  ]);

  const webAuctionsMapFilter = useGridFilter<WebAuctionsFilter>([
    {
      key: 'title',
      group: 'search',
      operator: 'like',
      property: 'auctionMapView.title',
      value: (v) => (!!v ? `%${v}%` : undefined),
    },
    {
      key: 'title',
      group: 'search',
      operator: 'like',
      property: 'auctionMapView.number',
      value: (v) => (!!v ? `%${v.replaceAll(' ', '')}%` : undefined),
    },
    {
      type: 'int',
      key: 'priceFrom',
      property: 'auctionMapView.minimalBid',
      operator: 'gte',
    },
    {
      type: 'int',
      key: 'priceTo',
      property: 'auctionMapView.minimalBid',
      operator: 'lte',
    },
    {
      type: 'string',
      key: 'status',
      property: 'auctionMapView.status',
      value: (val) => (val === 'overcharge' ? 'ended_overcharge' : val),
    },
    {
      type: 'int',
      key: 'mainCategory',
      property: 'auctionMapView.auctionCategoryParentId',
      operator: 'eq',
    },
    {
      group: 'regions',
      type: 'int',
      key: 'regions',
      property: 'auctionMapView.regionId',
      operator: 'eq',
    },
    {
      group: 'district',
      type: 'int',
      key: 'districts',
      property: 'auctionMapView.districtId',
      operator: 'eq',
    },
    {
      group: 'districtPart',
      type: 'int',
      key: 'districtsParts',
      property: 'auctionMapView.districtPartId',
      operator: 'eq',
    },
    {
      type: 'int',
      operator: 'eq',
      key: 'subCategories',
      group: 'subCategories',
      property: 'auctionMapView.auctionCategoryId',
    },
    {
      operator: 'eq',
      type: 'string',
      key: 'auctionType',
      group: 'auctionType',
      property: 'auctionMapView.auctionType',
    },
  ]);

  const auctionBuyersFilter = useGridFilter<AuctionUsersFilters>([
    {
      operator: 'eq',
      key: 'auctionId',
      property: 'auction.id',
    },
    {
      operator: 'eq',
      key: 'uniqueCode',
      property: 'userAuction.uniqueCode',
    },
    {
      operator: 'eq',
      key: 'specificSymbol',
      property: 'userAuction.specificSymbol',
    },
    {
      operator: 'eq',
      key: 'variableSymbol',
      property: 'userAuction.variableSymbol',
    },
    {
      operator: 'eq',
      key: 'preEmption',
      property: 'userAuction.preEmption',
    },
    {
      operator: 'eq',
      key: 'admitted',
      property: 'userAuction.admitted',
    },
    {
      operator: 'eq',
      key: 'auctionSecurity',
      group: 'auctionSecurity',
      property: 'userAuction.auctionSecurity',
    },
    {
      operator: 'eq',
      key: 'authorizationState',
      property: 'userAuction.authorizationState',
    },
    {
      operator: 'like',
      key: 'fullName',
      property: 'user.fullName',
      value: (val) => (val ? `%${val}%` : undefined),
    },
    {
      operator: 'eq',
      key: 'withoutWinner',
      property: 'userAuction.isWinner',
      value: (val) => (val ? false : undefined),
    },
    {
      key: 'status',
      group: 'status',
      property: 'auction.status',
    },
    {
      key: 'number',
      operator: 'like',
      property: 'auction.number',
      value: (val) => (val ? `%${val.replaceAll(' ', '')}%` : undefined),
    },
  ]);

  const userAuctionsFilter = useGridFilter<WebAuctionsFilter>([
    {
      group: 'statusGroup',
      type: 'string',
      key: 'status',
      property: 'auction.status',
    },
  ]);

  const auctionEmailsFilter = useGridFilter([
    {
      key: 'content',
      operator: 'fulltext',
      property: 'sentEmail.content',
      value: (val) => (val ? `+${val}` : undefined),
    },
    {
      key: 'subject',
      operator: 'eq',
      property: 'sentEmail.subject',
    },
    {
      key: 'email',
      operator: 'eq',
      property: 'sentEmail.email',
    },
  ]);

  const getFilterOperator = (prop: string, value?: string | boolean): string => {
    switch (prop) {
      case 'title':
        return 'like';
      case 'number':
        return 'like';
      case 'startDtFrom':
        return 'gte';
      case 'startDtTo':
        return 'lte';
      case 'endDtFrom':
        return 'gte';
      case 'endDtTo':
        return 'lte';
      case 'published':
        return 'eq';
      default:
        return 'eq';
    }
  };

  const getFilterProperty = (prop: string): string => {
    switch (prop) {
      case 'id':
        return 'auction.id';
      case 'title':
        return 'auction.title';
      case 'number':
        return 'auction.number';
      case 'auctionType':
        return 'auction.auctionType';
      case 'state':
        return 'auction.state';
      case 'mainCategory':
        return 'auctionCategory.parent';
      case 'subCategory':
        return 'auctionCategory.id';
      case 'status':
        return 'auction.status';
      case 'startDtFrom':
        return 'auction.startDt';
      case 'startDtTo':
        return 'auction.startDt';
      case 'endDtFrom':
        return 'auction.endDt';
      case 'endDtTo':
        return 'auction.endDt';
      case 'published':
        return 'auctionExports.isExportedToExdrazby';
      case 'auctioneer':
        return 'auctioneer.id';
      default:
        return prop;
    }
  };

  const getFilterValue = (prop: string, value: string | boolean): string | boolean | undefined => {
    if (prop === 'published') {
      return value;
    }

    if (value === false) {
      return undefined;
    }

    switch (prop) {
      case 'title':
        return !!value ? `%${value}%` : undefined;
      case 'number':
        return !!value ? `%${value?.toString().replaceAll(' ', '')}%` : undefined;
      default:
        return value;
    }
  };

  return {
    ...api,
    getAuctionRequiredFields(auctionId, categoryId) {
      const cancelToken = api.prepareCancelToken('getAuctionRequiredFields');
      return api.authRequest.get(getAuctionRequiredFieldsPath(auctionId, categoryId), { cancelToken });
    },
    getAuctionStatusGroups(auctionType: string, currentStatus?: string) {
      const cancelToken = api.prepareCancelToken('getAuctionStatusGroups');
      return api.request.get(auctionStatusGroupsPath(auctionType), { cancelToken, params: { currentStatus } });
    },
    getAllStatuses() {
      const cancelToken = api.prepareCancelToken('getAllStatuses', true);
      return api.request.get(allStatusesPath(), { cancelToken });
    },
    getAuctionStatusGroup(statusGroup: string) {
      const cancelToken = api.prepareCancelToken('getAuctionStatusGroup', true);
      return api.request.get(auctionStatusGroupPath(statusGroup), { cancelToken });
    },
    getAuctionAuctionType(auctionTypeGroup: string) {
      const cancelToken = api.prepareCancelToken('getAuctionAuctionType');
      return api.request.get(auctionTypePath(auctionTypeGroup), { cancelToken });
    },
    addAuctionToFavourites(auctionId: number | string) {
      const cancelToken = api.prepareCancelToken('addToFavoutite');
      return api.authFrontRequest.post(userFavouriteAuctionPath(auctionId), {}, { cancelToken });
    },
    removeAuctionFromFavourites(auctionId: number | string) {
      const cancelToken = api.prepareCancelToken('removeFromFavourites');
      return api.authFrontRequest.delete(userFavouriteAuctionPath(auctionId), { cancelToken });
    },
    joinAuction(hash: string) {
      const cancelToken = api.prepareCancelToken('joinAuction');
      return api.authFrontRequest.post(userJoinAuctionPath(hash), {}, { cancelToken });
    },
    leaveAuction(auctionId: number | string) {
      const cancelToken = api.prepareCancelToken('leaveAuction');
      return api.authFrontRequest.delete(userAuctionPath(auctionId), { cancelToken });
    },
    listTypes() {
      const cancelToken = api.prepareCancelToken('listTypes');
      return api.authRequest.get(auctionTypesPath(), { cancelToken });
    },
    deleteAuction(id: string | number) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(auctionPath(id), { cancelToken });
    },
    auctionCreate(data: AuctionRequest) {
      const cancelToken = api.prepareCancelToken('auctionCreate');
      return api.authRequest.post(auctionsPath(), data, { cancelToken });
    },
    auctionUpdate(id: string | number, data: AuctionRequest) {
      const cancelToken = api.prepareCancelToken('auctionUpdate');
      return api.authRequest.put(auctionPath(id), data, { cancelToken });
    },
    getFavouriteAuctions(params: AuctionFilters) {
      const cancelToken = api.prepareCancelToken('getFavouriteAuctions');
      return api.authFrontRequest.get(userFavouriteAuctionsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
        },
      });
    },
    async getUserAuctions(params: AuctionFilters) {
      if (!!params.status) {
        if (params.status === 'ended_overcharge') {
          _.set(params, 'status', ['ended_overchage']);
        } else {
          try {
            const response = await this.getAuctionStatusGroup(params.status);
            _.set(
              params,
              'status',
              response.data.data.map((item) => item.type)
            );
          } catch (err) {
            console.error(err);
            _.set(params, 'status', []);
          }
        }
      }
      const cancelToken = api.prepareCancelToken('getUserAuctions');
      return api.authFrontRequest.get(userAuctionsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          filter: !userAuctionsFilter.isEmpty(params) ? userAuctionsFilter.toString(params) : undefined,
        },
      });
    },
    getUserAuctionsCount() {
      const cancelToken = api.prepareCancelToken('getUserAuctionsCount');
      return api.authFrontRequest.get(userAuctionsCountPath(), { cancelToken });
    },
    getUserAuction(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getUserAuction');
      return api.authFrontRequest.get(userAuctionPath(auctionId), { cancelToken });
    },
    async getWebAuctions(params: WebAuctionsFilter, sortProp: string, sortDirection: string, cancelName?: string) {
      const cancelToken = api.prepareCancelToken(cancelName || 'getWebAuctions');
      return (authFrontStore.getState().isLogged ? api.authFrontRequest : api.request).get(webAuctionsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          //@TODO kvůli rychlosti je lepší filtrovat přes ID aukce, než například přes datum
          sort: JSON.stringify({ property: sortProp, direction: sortDirection }),
          filter: !webAuctionsFilter.isEmpty(params) ? webAuctionsFilter.toString(params) : undefined,
        },
      });
    },
    async getWebMapAuctions(params: WebAuctionsFilter) {
      const cancelToken = api.prepareCancelToken('getWebMapAuctions');
      return (authFrontStore.getState().isLogged ? api.authFrontRequest : api.request).get(webMapAuctionsPath(), {
        cancelToken,
        params: {
          filter: !webAuctionsMapFilter.isEmpty(params) ? webAuctionsMapFilter.toString(params) : undefined,
        },
      });
    },
    getWebAuctionsCount() {
      const cancelToken = api.prepareCancelToken('auctionsCount');
      return api.request.get(webAuctionsCountPath(), { cancelToken });
    },
    getWebAuctionTypeList() {
      const cancelToken = api.prepareCancelToken('getWebAuctionTypeList');
      return api.request.get(webAuctionsTypesPath(), { cancelToken });
    },
    getWebAuctionInformations(auctionId: string | number, type: string) {
      const cancelToken = api.prepareCancelToken('getWebAuctionInformations');
      return (authFrontStore.getState().isLogged ? api.authFrontRequest : api.request).get(
        webAuctionInformationsPath(auctionId),
        {
          cancelToken,
          params: { type },
        }
      );
    },
    getAuction(id: string | number) {
      const cancelToken = api.prepareCancelToken('getAuction');
      return api.authRequest.get(auctionPath(id), { cancelToken });
    },
    getAuctions(params: AdminAuctionFilters) {
      const cancelToken = api.prepareCancelToken('getAuctions');
      let queryParams: any = { ...params };
      delete queryParams.page;
      delete queryParams.perPage;
      delete queryParams.sort;
      const filters: any = Object.getOwnPropertyNames(queryParams)
        .filter((prop) => prop !== 'auctionType' && prop !== 'status')
        .map((prop) => ({
          operator: getFilterOperator(prop, queryParams[prop]),
          value: getFilterValue(prop, queryParams[prop]),
          property: getFilterProperty(prop),
        }))
        .filter((item) => item.value !== undefined);

      if (queryParams.auctionType) {
        if (queryParams.auctionType !== 'public') {
          filters.push({
            operator: getFilterOperator('auctionType'),
            value: getFilterValue('auctionType', queryParams.auctionType),
            property: getFilterProperty('auctionType'),
          });
        } else {
          filters.push([
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'public_involuntary'),
              property: getFilterProperty('auctionType'),
            },
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'public_voluntary'),
              property: getFilterProperty('auctionType'),
            },
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'executor_auction_voluntary'),
              property: getFilterProperty('auctionType'),
            },
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'executor_auction_involuntary'),
              property: getFilterProperty('auctionType'),
            },
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'auction_public'),
              property: getFilterProperty('auctionType'),
            },
            {
              operator: getFilterOperator('auctionType'),
              value: getFilterValue('auctionType', 'auction_voluntary'),
              property: getFilterProperty('auctionType'),
            },
          ]);
        }
      }

      if (!queryParams.state) {
        filters.push([
          {
            operator: getFilterOperator('state'),
            value: getFilterValue('state', 'active'),
            property: getFilterProperty('state'),
          },
          {
            operator: getFilterOperator('state'),
            value: getFilterValue('state', 'deactive'),
            property: getFilterProperty('state'),
          },
        ]);
      }

      if (_.isArray(queryParams.status)) {
        filters.push(
          queryParams.status.map((s: string) => ({
            operator: getFilterOperator('status'),
            value: getFilterValue('status', s),
            property: getFilterProperty('status'),
          }))
        );
      }

      return api.authRequest.get(auctionsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: params.sort ? JSON.stringify(params.sort) : undefined,
          filter: filters.length > 0 ? JSON.stringify(filters) : undefined,
        },
      });
    },
    getAuctionStatus(auctionType?: string) {
      return api.request.get(auctionStatusPath(), { params: { auctionType } });
    },
    getAuctionBuyers(auctionId: string | number, params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionBuyers');
      return api.authRequest.get(auctionBuyersPath(auctionId), {
        cancelToken,
        params: {
          page: params?.page,
          perPage: params?.perPage,
          sort: params?.sort ? JSON.stringify(params.sort) : undefined,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    getAuctionSecurityBuyers(auctionId: string | number, params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionSecurityBuyers');
      return api.authRequest.get(auctionSecurityBuyersPath(auctionId), {
        cancelToken,
        params: {
          page: params?.page,
          perPage: params?.perPage,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    getAuctionSecurityExcel(auctionId?: string | number, params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionSecurityExcel');
      return api.authRequest.get(auctionId ? auctionSecurityExcelPath(auctionId) : auctionSecurityExcelAllPath(), {
        cancelToken,
        responseType: 'blob',
        params: {
          view: true,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    getAuctionSecurityGpc(auctionId?: string | number, params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionSecurityGpc');
      return api.authRequest.get(auctionId ? auctionSecurityGpcPath(auctionId) : auctionSecurityGpcAllPath(), {
        cancelToken,
        responseType: 'blob',
        params: {
          view: true,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    getAuctionUsersExcel(auctionId: string | number, params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionUsersExcel');
      return api.authRequest.get(auctionUsersExcelPath(auctionId), {
        cancelToken,
        responseType: 'blob',
        params: {
          view: true,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    getAuctionType(auctioneerId: string | number) {
      const cancelToken = api.prepareCancelToken('getAuctionType');
      return api.authRequest.get(auctioneerAuctionTypePath(auctioneerId), { cancelToken });
    },
    getAuctionTypeByType(
      auctioneerId: string | number,
      auctionType: 'auction' | 'auction_public',
      auctionId?: string | number
    ) {
      const cancelToken = api.prepareCancelToken('getAuctionTypeByType');
      return api.authRequest.get(auctioneerAuctionTypeByTypePath(auctioneerId, auctionType), {
        cancelToken,
        params: { auctionId },
      });
    },
    getAuctionExports(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getAuctionExports');
      return api.authRequest.get(auctionExportsPath(auctionId), { cancelToken });
    },
    getAuctionExportsHistory(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getAuctionExportsHistory');
      return api.authRequest.get(auctionExportsHistoryPath(auctionId), { cancelToken });
    },
    exportAuction(
      auctionId: string | number,
      server: string,
      dateFrom?: Date,
      dateTo?: Date,
      weeks?: string,
      previewImage?: string
    ) {
      const cancelToken = api.prepareCancelToken('exportAuction');
      return api.authRequest.put(
        auctionExportsPath(auctionId),
        {
          server,
          dateFrom,
          weeks,
          previewImage,
          dateTo: !!weeks ? undefined : dateTo,
        },
        { cancelToken }
      );
    },
    cancelExportAuction(auctionId: string | number, server: string) {
      const cancelToken = api.prepareCancelToken('cancelExportAuction');
      return api.authRequest.put(auctionExportsCancelPath(auctionId), { server }, { cancelToken });
    },
    getItemType() {
      const cancelToken = api.prepareCancelToken('getItemType');
      return api.request.get(auctionItemTypePath(), { cancelToken });
    },
    getDocumentTypes(auctionType: string) {
      const cancelToken = api.prepareCancelToken('getDocumentTypes');
      return api.request.get(documentTypesPath(auctionType), { cancelToken });
    },
    getDocument(auctionId: string | number, documentId: string | number) {
      return api.authRequest.get(documentPath(auctionId, documentId), {
        params: { view: true },
        responseType: 'blob',
      });
    },
    getDocuments(id: string | number) {
      const cancelToken = api.prepareCancelToken('getDocuments');
      return api.authRequest.get(getDocumentsPath(id), { cancelToken });
    },
    deleteDocument(auctionId: string | number, documentId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteDocument');
      return api.authRequest.delete(documentPath(auctionId, documentId), { cancelToken });
    },
    createDocument(auctionId: string | number, documentType: string, data: AuctionDocumentCreate) {
      const cancelToken = api.prepareCancelToken('createDocument');
      return api.authRequest.post(createDocumentPath(auctionId, documentType), data, { cancelToken });
    },
    renameDocument(auctionId: string, documentId: string, title: string) {
      const cancelToken = api.prepareCancelToken('renameDocument');
      return api.authRequest.put(renameDocumentPath(auctionId, documentId), { title }, { cancelToken });
    },
    createDocumentPrivate(auctionId: string | number, data: AuctionDocumentCreate) {
      const cancelToken = api.prepareCancelToken('createDocumentPrivate');
      return api.authRequest.post(createDocumentPrivatePath(auctionId), data, { cancelToken });
    },
    getImages(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getImages');
      return api.authRequest.get(imagesPath(auctionId), { cancelToken });
    },
    createImage(auctionId: string | number, data: AuctionImageCreate) {
      const cancelToken = api.prepareCancelToken('createImage');
      return api.authRequest.post(imagesPath(auctionId), data, { cancelToken });
    },
    createVideo(auctionId: string | number, data: AuctionVideoCreate) {
      const requestData = { ...data };
      _.set(requestData, 'type', 'embed');
      const cancelToken = api.prepareCancelToken('createVideo');
      return api.authRequest.post(imagesPath(auctionId), requestData, { cancelToken });
    },
    deleteImage(auctionId: string | number, imageId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteImage');
      return api.authRequest.delete(mediaPath(auctionId, imageId), { cancelToken });
    },
    getMedia(auctionId: string | number, mediaId: string | number, size?: string) {
      return api.authRequest.get(mediaPath(auctionId, mediaId), {
        params: { size, view: true },
        responseType: 'blob',
      });
    },
    auctionDelete(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionDelete');
      return api.authRequest.delete(auctionDeletePath(auctionId), { cancelToken });
    },
    auctionActivate(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionActivate');
      return api.authRequest.get(auctionActivatePath(auctionId), { cancelToken });
    },
    auctionDeactive(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionDeactive');
      return api.authRequest.get(auctionDeactivePath(auctionId), { cancelToken });
    },
    reorderAuctionImages(
      auctionId: string | number,
      imagesOrder: Array<{
        auctionMediaId: string | number;
        position: number;
      }>
    ) {
      const cancelToken = api.prepareCancelToken('reorderAuctionImages');
      return api.authRequest.put(reorderAuctionImagesPath(auctionId), imagesOrder, { cancelToken });
    },
    getHistory(auctionId: string | number, perPage?: number, page?: number) {
      const cancelToken = api.prepareCancelToken('getHistory');
      return api.authRequest.get(auctionHistoryPath(auctionId), {
        cancelToken,
        params: {
          page: page,
          perPage: perPage,
        },
      });
    },
    copyConcept(conceptId: string | number) {
      const cancelToken = api.prepareCancelToken('copyConcept');
      return api.authRequest.post(copyConceptPath(conceptId), { cancelToken });
    },
    saveConcept(values: any, auctionType: string, conceptId?: string | number) {
      const cancelToken = api.prepareCancelToken('saveConcept');
      if (!!conceptId) {
        return api.authRequest.put(
          updateConceptPath(conceptId),
          {
            auctionType,
            data: JSON.stringify(values),
          },
          { cancelToken }
        );
      } else {
        return api.authRequest.post(
          createConceptPath(),
          {
            auctionType,
            data: JSON.stringify(values),
          },
          { cancelToken }
        );
      }
    },
    getContept(conceptId: string | number) {
      const cancelToken = api.prepareCancelToken('getContept');
      return api.authRequest.get(getConceptPath(conceptId), { cancelToken });
    },
    getConteptList(params: PaginationParams) {
      const cancelToken = api.prepareCancelToken('getConteptList');
      return api.authRequest.get(getConceptListPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: 'id', direction: 'DESC' }),
        },
      });
    },
    deleteConcept(id: string | number) {
      const cancelToken = api.prepareCancelToken('deleteConcept');
      return api.authRequest.delete(deleteConceptPath(id), { cancelToken });
    },
    getAuthorizationType(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getAuthorizationType');
      return api.authRequest.get(getAuthorizationTypePath(auctionId), { cancelToken });
    },
    getCautionDepositCounterInfo(auctionId: string | number, userId: string | number) {
      const cancelToken = api.prepareCancelToken('getCautionDepositCounterInfo');
      return api.authRequest.get(getCautionDepositCounterInfo(auctionId, userId), { cancelToken });
    },
    getCautionDeposit(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getCautionDeposit');
      return api.authRequest.get(getCautionDepositPath(auctionId), { cancelToken });
    },
    getBidsAdmin(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getBidsAdmin');
      return api.authRequest.get(getBidsAdmin(auctionId), { cancelToken });
    },
    getWebBids(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getWebBids');
      return api.authFrontRequest.get(getWebBidsPath(auctionId), { cancelToken });
    },
    acceptWebMinBid(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('acceptWebMinBid');
      return api.authFrontRequest.get(acceptWebMinBidPath(auctionId), { cancelToken });
    },
    createWebBid(auctionId: string | number, values: WebAuctionBidRequest) {
      const cancelToken = api.prepareCancelToken('createWebBid');
      return api.authFrontRequest.post(createWebBidPath(auctionId), values, { cancelToken });
    },
    getWinner(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getWinner');
      return api.request.get(winnerPath(auctionId), { cancelToken });
    },
    auctionParameterShort(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionParameterShort');
      return api.request.get(auctionParameterShortPath(auctionId), { cancelToken });
    },
    auctionParameterComplete(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionParameterComplete');
      return api.request.get(auctionParameterCompletePath(auctionId), { cancelToken });
    },
    getAuctionMessagesType() {
      const cancelToken = api.prepareCancelToken('getAuctionMessagesType');
      return api.request.get(auctionMessagesTypePath(), { cancelToken });
    },
    sendAuctionMessage(auctionId: string | number, values: AuctionSendMessageValues) {
      const cancelToken = api.prepareCancelToken('sendAuctionMessage');
      if (values.auctionMessageType !== 'buyer_own_choice') {
        delete values.auctionMessageBuyers;
      }
      return api.authRequest.post(auctionSendMessagesPath(auctionId), values, { cancelToken });
    },
    preEmptionBid(auctionId: string | number, bidPrice: string | number) {
      const cancelToken = api.prepareCancelToken('preEmptionBid');
      return api.authFrontRequest.post(preEmptionBidPath(auctionId), { bidPrice }, { cancelToken });
    },
    uploadMessageAttachment(data: Partial<DocumentCreate>) {
      const cancelToken = api.prepareCancelToken('uploadMessageAttachment');
      return api.authRequest.post(auctionMessageAttachment(), data, { cancelToken });
    },
    auctionDraw(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('auctionDraw');
      return api.authFrontRequest.post(auctionDrawPath(auctionId), { cancelToken });
    },
    auctionExtend(auctionId: string | number, endDt: string) {
      const cancelToken = api.prepareCancelToken('auctionExtend');
      return api.authRequest.put(auctionExtendPath(auctionId), { endDt }, { cancelToken });
    },
    auctionExportPreview(auctionId: string | number, imagePreview: string) {
      const cancelToken = api.prepareCancelToken('auctionExportPreview');
      return api.authRequest.get(auctionExportPreviewPath(auctionId, imagePreview), {
        cancelToken,
      });
    },
    printAuction(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('printAuction');
      return api.authRequest.get(getAuctionPrintPath(auctionId), { cancelToken, responseType: 'blob' });
    },
    getAuctionsSecurity(params?: AuctionUsersFilters) {
      const cancelToken = api.prepareCancelToken('getAuctionsSecurity');
      return api.authRequest.get(getAuctionsSecurityPath(), {
        cancelToken,
        params: {
          page: params?.page,
          perPage: params?.perPage,
          filter: !!params && !auctionBuyersFilter.isEmpty(params) ? auctionBuyersFilter.toString(params) : undefined,
        },
      });
    },
    auctionBackSecurity(userId: string | number, auctionId: string | number, data: AuctionBackSecurityData) {
      const cancelToken = api.prepareCancelToken('auctionBackSecurity');
      return api.authRequest.put(getAuctionBackSecurityPath(userId, auctionId), data, { cancelToken });
    },
    updateAuctionOutage(auctionId: string | number, data: AuctionUpdateOutageData) {
      const cancelToken = api.prepareCancelToken('updateAuctionOutage');
      return api.authRequest.put(updateAuctionOutagePath(auctionId), data, { cancelToken });
    },
    updateAuctionOngoing(auctionId: string | number, data: AuctionUpdateOutageData) {
      const cancelToken = api.prepareCancelToken('updateAuctionOngoing');
      return api.authRequest.put(updateAuctionOngoingPath(auctionId), data, { cancelToken });
    },
    getAuctionEmails(auctionId: string | number, params?: AuctionEmailsFilter) {
      const cancelToken = api.prepareCancelToken('getAuctionEmails');
      return api.authRequest.get(auctionEmailsPath(auctionId), {
        cancelToken,
        params: {
          page: params?.page,
          perPage: params?.perPage,
          sort: params?.sort ? JSON.stringify(params.sort) : undefined,
          filter: params && !auctionEmailsFilter.isEmpty(params) ? auctionEmailsFilter.toString(params) : undefined,
        },
      });
    },
  };
};
